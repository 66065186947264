.con-inputs-job-prices{
    display: flex;
    align-items: center;
    height: 8.6vh;
    border: solid 1px black;
    border-radius: 3px;
    padding: 1.5rem 1rem 0 1rem;
    position: relative;
    z-index: 2;
}


.con-inputs-job-prices > :not(:last-child) {
    margin-right: 1.2%; /* Adjust the margin value as needed */
}