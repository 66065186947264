.con-header-table-user-form{
    padding-top: 2.6rem;
    padding-bottom: .7rem;
    font-weight: 500;
    border: solid 1px var(--manatee);
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
}

.user-form-item1{
    width: 3%;
}
  
.user-form-item2{
    width: 18%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: .5rem;
}
  
.user-form-item3{
    width: 9%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-right: 1rem;
}
  
.user-form-item4{
    width: 21%;
    overflow: hidden;
}
  
.user-form-item5{
    width: 11%;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
  
.user-form-item6{
    width: 10%;
}
  
.user-form-item7{
    width: 18%;
}
  
.user-form-item8{
    width: 7%;
}
  
.user-form-item9{
    width: 3%;
}
  


.con-item-header-user-form{
    display: flex;
    align-items: center;
    color: var(--oslo-gray);
    margin-top: .4rem;
}