
.container-screens{
    display: flex;
    flex: 1;
    justify-content: center;

  }
  
  .container-content{
    display: flex;
    justify-content: space-between;
    max-width: var(--max-width-container);
    height: 100%;
    width: 90vw;
  }
  
  .container-sidebar{
    width: 18%;
  }
  
  .container-screen{
    flex: 1;
  }
  
  .container-aux-design-screen{
    padding: var(--padding-content-sidebar-screens);
    height: 100%;
  }


  @media only screen and (max-width: 1500px) {
    .container-content{
      width: 100vw;
      max-width: var(--max-width-container) + 15rem;
    }

    .container-aux-design-screen{
        width: 100%;
    }
}
    