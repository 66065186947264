.file-button{
    background-color: white;
    border-radius: .3rem;
    padding: .9rem 1.2rem .9rem 1.2rem;
    border: 1px solid var(--manatee);
    width: 14rem;
}

.file-button:hover{
    filter: brightness(.9);
}